/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { sigUp, signIn } from "../../../services/authServices";
import { Toast } from "react-bootstrap";
import { sailogo1 } from "../../../Routes/ImagePath";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(4, "Password must be at least 4 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required"),
});

const Login = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();

  const [showDangerToast, setShowDangerToast] = useState(false);
  const handleDangerToastClose = () => {
    setShowDangerToast(false);
  };

  const handleDangerToastButtonClick = () => {
    setShowDangerToast(true);
  };

  const onSubmit = async (data) => {
    let sigInDetails = await signIn(data);
    if (sigInDetails?.Body) {
      // navigate("/admin-dashboard");
      navigate("/billing-data");
    } else {
      handleSolidDangerToastButtonClick();
    }
  };

  useEffect(() => {
    setValue("email", localStorage.getItem("email"));
    setValue("password", localStorage.getItem("password"));
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  const [showTopEndToast, setShowTopEndToast] = useState(false);

  const handleTopEndButtonClick = () => {
    setShowTopEndToast(true);
  };

  const handleTopEndToastClose = () => {
    setShowTopEndToast(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowTopEndToast(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [showTopEndToast]);
  const [showSolidDangerToast, setShowSolidDangerToast] = useState(false);

  const handleSolidDangerToastButtonClick = () => {
    setShowSolidDangerToast(true);
  };

  const handleSolidDangerToastClose = () => {
    setShowSolidDangerToast(false);
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSolidDangerToast(false);
    }, 6000);
    return () => clearTimeout(timeoutId);
  }, [showSolidDangerToast]);

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="/admin-dashboard">
                  <img src={sailogo1} alt="Dreamguy's Technologies" />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">
                    Access to Energy Accounting
                  </p>
                  {/* Account Form */}
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">User Id</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              defaultValue={localStorage.getItem("email")}
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                              placeholder="Type youe user id"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {errors.email?.message}
                        </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                type={eye ? "password" : "text"}
                                defaultValue={localStorage.getItem("password")}
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="Type your password"
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            } `}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.password?.message}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-info account-btn"
                          type="submit"
                        >
                          Login
                        </button>
                        <div className="toast-container position-fixed top-0 end-0 p-3">
                          <Toast
                            show={showSolidDangerToast}
                            onClose={handleSolidDangerToastClose}
                            id="solidDangerToast"
                            className="colored-toast bg-danger text-fixed-white"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                          >
                            <Toast.Header
                              closeButton
                              className="bg-danger text-fixed-white"
                            >
                              <strong className="me-auto h4 text-light">
                                Invalid user id or password
                              </strong>
                            </Toast.Header>
                          </Toast>
                        </div>
                      </div>
                    </form>
                    <div className="account-footer">
                      <p>
                        Don't have an account yet?&nbsp;
                        <Link to="/signup">Register</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
