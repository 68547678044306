import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { notification } from "antd";

const base_Url = "https://stage-tri-energy-accounting.thesaicomputers.com/ea";
// const base_Url = "http://localhost:8089";
// const base_Url = "http://127.0.0.1:5000/scl_ea";
// const base_Url = "http://162.147.132.209:2553/scl_ea";

// Retrieve the token from localStorage or define it
const token = localStorage.getItem("authToken");

export function sendOtp(data) {
  const requestBody = {
    USER_ID: data.USER_ID,
    MOBILE_NO: data.METHOD == "MOBILE" ? data.MOBILE_NO : "",
    EMAIL: data.METHOD == "EMAIL" ? data.EMAIL : "",
    RESET_OPTION: data.METHOD,
    SECURITY_ANSWER: "",
  };
  return new Promise((res, rej) => {
    axios.post(`${base_Url}/static/getOtp`, requestBody).then((response) => {
      return res(response.data);
    });
  }).catch((error) => {
    notification.error(error);
  });
}

export function validateOtp(body) {
  return new Promise((res, rej) => {
    axios
      .post(`${base_Url}/static/validate-otp`, body)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        notification.error(error);

        rej(error); // Pass the error to the rejection handler
      });
  });
}

export function uploadBillingData(bill, bill_month, bill_year) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    // Append fields to FormData
    formData.append("bill", bill);
    formData.append("bill_month", bill_month);
    formData.append("bill_year", bill_year);
    formData.append("token", token); // Append token to the form data

    try {
      const response = await axios.post(`${base_Url}/bill_upload`, formData, {
        headers: {
          Authorization: `${token}`, // Bearer token in the header
          "Content-Type": "multipart/form-data/json", // Automatically set by FormData
        },
      });
      resolve(response.data); // Resolve with response data
    } catch (error) {
      reject(error); // Reject the promise with the error
    }
  });
}
export function uploadCollectionData(pay, bill_month, bill_year) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    // Append fields to FormData
    formData.append("pay", pay);
    formData.append("bill_month", bill_month);
    formData.append("bill_year", bill_year);
    formData.append("token", token); // Append token to the form data

    try {
      const response = await axios.post(`${base_Url}/pay_upload`, formData, {
        headers: {
          Authorization: `${token}`, // Bearer token in the header
          "Content-Type": "multipart/form-data/json", // Automatically set by FormData
        },
      });
      resolve(response.data); // Resolve with response data
    } catch (error) {
      reject(error); // Reject the promise with the error
    }
  });
}

export function uploadFeederData(feeder, bill_month, bill_year) {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();

    // Append fields to FormData
    formData.append("feeder", feeder);
    formData.append("bill_month", bill_month);
    formData.append("bill_year", bill_year);
    formData.append("token", token); // Append token to the form data

    try {
      const response = await axios.post(`${base_Url}/feeder_upload`, formData, {
        headers: {
          Authorization: `${token}`, // Bearer token in the header
          "Content-Type": "multipart/form-data/json", // Automatically set by FormData
        },
      });
      resolve(response.data); // Resolve with response data
    } catch (error) {
      reject(error); // Reject the promise with the error
    }
  });
}

export function getZoneWiseReportData() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${base_Url}/report`)
      .then((response) => {
        resolve(response.data); // Resolve the promise with the data
      })
      .catch((error) => {
        reject(error); // Reject with error
      });
  });
}

export function getAllDivisionUnderCircle(CIRCLE_CODE) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${base_Url}/static/get-division-by-circle?CIRCLE_CODE=${CIRCLE_CODE}`
      )
      .then((response) => {
        resolve(response.data); // Resolve the promise with the data
      })
      .catch((error) => {
        notification.error(error);

        reject(error); // Reject with error
      });
  });
}

export function getApprovalDetails() {
  return new Promise((resolve, reject) => {
    try {
      const token = localStorage.getItem("authToken"); // Retrieve token
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      axios
        .get(`${base_Url}/user/approval-details/PENDING`, {
          headers: {
            Authorization: `${token}`, // Add Bearer token
            "Content-Type": "application/json", // Proper content type for JSON
          },
        })
        .then((response) => {
          resolve(response.data); // Resolve the promise with response data
        })
        .catch((error) => {
          notification.error(error);

          reject(error); // Reject the promise with the error
        });
    } catch (error) {
      notification.error(error);

      reject(error); // Reject with unexpected error
    }
  });
}

export function approveUser(data, token) {
  const decodedToken = jwtDecode(token);

  const requestBody = {
    AREA_LEVEL: data.AREA_LEVEL || "DISCOM",
    AREA_CODE: data.AREA_CODE || "MEPDCL",
    id: data.id,
    AREA: data.AREA || "MEPDCL",
    APPROVED_STATUS: "APPROVED",
    DESG: decodedToken.DESG,
  };

  return new Promise((res, rej) => {
    axios
      .post(`${base_Url}/user/assign-fact-area`, requestBody, {
        headers: {
          Authorization: `${token}`, // Passing token in headers
        },
      })
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        notification.error(error);

        rej(error); // Call reject in case of error
      });
  });
}

export function rejectUser(data, token) {
  const requestBody = {
    user_id: data.user_id,
    // user_id: "USR0004",
  };

  return new Promise((res, rej) => {
    axios
      .post(`${base_Url}/user/reject-user`, requestBody, {
        headers: {
          Authorization: `${token}`, // Passing token in headers
        },
      })
      .then((response) => {
        return res(response.data);
      })
      .catch((error) => {
        notification.error(error);

        rej(error); // Call reject in case of error
      });
  });
}

export function getTableDataFeederWise(
  BILLING_YEAR,
  BILLING_MONTH,
  FEEDER_CODE
) {
  console.log(
    "BILLING_YEAR,BILLING_MONTH,FEEDER_CODE",
    BILLING_YEAR,
    BILLING_MONTH,
    FEEDER_CODE
  );
  const body = {
    BILLING_YEAR: Number(BILLING_YEAR),
    BILLING_MONTH: Number(BILLING_MONTH),
    FEEDER_CODE: FEEDER_CODE,
  };
  return new Promise(async (resolve, reject) => {
    axios
      .post(`${base_Url}/static/energy-accounting-final`, body)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTableDataZoneWise(BILLING_YEAR, BILLING_MONTH, ZONE_CODE) {
  console.log(
    "BILLING_YEAR, BILLING_MONTH, ZONE_CODE",
    BILLING_YEAR,
    BILLING_MONTH,
    ZONE_CODE
  );
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${base_Url}/static/energy-accounting-final-zone-wise/${BILLING_YEAR}/${BILLING_MONTH}/${ZONE_CODE}`
      )
      .then((response) => {
        console.log("response.data.newArr", response.data);
        resolve(response?.data);
      })
      .catch((error) => {
        console.log("errorerror", error);
        notification.error(error);
        reject(error);
      });
  });
}
