import React, { useState, useEffect } from "react";
import Select from "react-select";
import ReportDropdowns from "./ReportDropdowns";
import axios from "axios"; // Import axios for API calls
import ReportTable from "./ReportTableFeederWise";
import { useForm } from "react-hook-form";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design
import { InfoCircleOutlined } from "@ant-design/icons"; // Ant Design icon for "i"
import ReportTableFeederWise from "./ReportTableFeederWise";
import ReportTableZoneWise from "./ReportTableZoneWise";
import ReportTableDivisionWise from "./ReportTableDivisionWise";
import ReportTableCircleWise from "./ReportTableCircleWise";
import ReportTableSubDivWise from "./ReportTableSubDivWise";
import ReportTableSubStationWise from "./ReportTableSubStation";
const Report = ({ title }) => {
  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const year = [{ label: "2024", value: "2024" }];
  const [toggle, setToggle] = useState(false);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
        cursor: "pointer !important",
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Increase the z-index of the dropdown menu
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Increase z-index for the menu portal
    }),
  };

  const [filteredMonths, setFilteredMonths] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null); // Track selected month
  const [selectedYear, setSelectedYear] = useState(null); // Track selected year
  const [feeder, setFeeder] = useState(null);
  const [zone, setZone] = useState(null);
  const [circle, setCircle] = useState(null);
  const [division, setDivision] = useState(null);
  const [subDivision, setSubDivision] = useState(null);
  const [subStation, setSubStation] = useState(null);
  const { setValue } = useForm();
  useEffect(() => {
    if (currentYear === 2024) {
      const validMonths = months.filter((month) => month.value <= currentMonth);
      setFilteredMonths(validMonths);
    } else {
      setFilteredMonths(months);
    }
  }, []);

  useEffect(() => {
    setValue("report_month", localStorage.getItem("report_month"));
    setValue("report_year", localStorage.getItem("report_year"));
  }, [setValue]);

  const report_month = localStorage.getItem("report_month") || "";
  const report_year = localStorage.getItem("report_year") || "";

  const handleCheckboxChange = (event) => {
    const { id } = event.target;
    setSelectedCheckbox(id);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const onSubmit = async (data) => {
    const { report_month, report_year } = data;
  };

  return (
    <div>
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid col-md-12 d-flex justify-content-center">
            <div className="row w-100 h-100">
              {!toggle ? (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header relative">
                      <Tooltip
                        title="Info about Consumer Category"
                        placement="topRight"
                        arrow={true}
                      >
                        <InfoCircleOutlined
                          title="On selecting Quarterly, 3 months before selected month will be taken with the selected month."
                          className="block info-icon"
                          style={{
                            fontSize: "16px",
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            color: "#1890ff",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                      <h2 className="card-title mb-0">
                        {title} Wise Losses Report
                      </h2>
                    </div>
                    <div className="card-body">
                      <form action="#">
                        <div className="row">
                          <div className="col navbar-nav-scroll">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="custom-checkbox mb-3">
                                  <input
                                    type="radio"
                                    className="me-2"
                                    id="customControlValidation1"
                                    name="timeSlot"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label htmlFor="customControlValidation1">
                                    Monthly
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="custom-checkbox mb-3">
                                  <input
                                    type="radio"
                                    className="me-2"
                                    id="customControlValidation2"
                                    name="timeSlot"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label htmlFor="customControlValidation2">
                                    Quarterly
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="custom-checkbox mb-3">
                                  <input
                                    type="radio"
                                    className="me-2"
                                    id="customControlValidation3"
                                    name="timeSlot"
                                    onChange={handleCheckboxChange}
                                  />
                                  <label htmlFor="customControlValidation3">
                                    Yearly
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="input-block mb-3">
                                  <label className="col-form-label">
                                    <h4>Billing Month</h4>
                                  </label>
                                  <div className="col-md-12">
                                    <Select
                                      name="report_month"
                                      options={filteredMonths}
                                      placeholder="-- Select Month --"
                                      styles={customStyles}
                                      className="pointer"
                                      onChange={(selected) =>
                                        // setSelectedMonth(selected)
                                        {
                                          setValue(
                                            "report_month",
                                            selected?.value
                                          );
                                          localStorage.setItem(
                                            "report_month",
                                            selected?.value
                                          );
                                        }
                                      }
                                      menuPortalTarget={document.body} // Render the dropdown in a portal to the body
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="input-block mb-3">
                                  <label className="col-form-label">
                                    <h4>Billing Year</h4>
                                  </label>
                                  <div className="col-md-12">
                                    <Select
                                      name="report_year"
                                      options={year}
                                      placeholder="-- Select Year --"
                                      styles={customStyles}
                                      onChange={(selected) =>
                                        // setSelectedYear(selected)
                                        {
                                          setValue(
                                            "report_year",
                                            selected?.value
                                          );
                                          localStorage.setItem(
                                            "report_year",
                                            selected?.value
                                          );
                                        }
                                      }
                                      defaultValue={
                                        year.find(
                                          (y) => y.value === +report_year
                                        ) || null
                                      }
                                      menuPortalTarget={document.body}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-block mb-2">
                                <div className="message-inner">
                                  <form
                                    id="js-upload-form"
                                    className="w-100 col-md-12"
                                  >
                                    <div className="col">
                                      <div className="row">
                                        <ReportDropdowns
                                          setFeeder={setFeeder}
                                          setZone={setZone}
                                          setCircle={setCircle}
                                          setDivision={setDivision}
                                          setSubDivision={setSubDivision}
                                          setSubStation={setSubStation}
                                          title={title}
                                        />
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="btn-list">
                        <button
                          className="btn btn-primary me-1"
                          // onClick={handleDownload}
                          onClick={handleToggle}
                        >
                          Show Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {title === "Zone" ? (
                    <ReportTableZoneWise
                      setToggle={setToggle}
                      feeder={feeder}
                      zone={zone}
                      circle={circle}
                      division={division}
                      subDivision={subDivision}
                      subStation={subStation}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                    />
                  ) : title === "Circle" ? (
                    <ReportTableCircleWise
                      setToggle={setToggle}
                      feeder={feeder}
                      zone={zone}
                      circle={circle}
                      division={division}
                      subDivision={subDivision}
                      subStation={subStation}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                    />
                  ) : title === "Division" ? (
                    <ReportTableDivisionWise
                      setToggle={setToggle}
                      feeder={feeder}
                      zone={zone}
                      circle={circle}
                      division={division}
                      subDivision={subDivision}
                      subStation={subStation}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                    />
                  ) : title === "Sub Division" ? (
                    <ReportTableSubDivWise
                      setToggle={setToggle}
                      feeder={feeder}
                      zone={zone}
                      circle={circle}
                      division={division}
                      subDivision={subDivision}
                      subStation={subStation}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                    />
                  ) : title === "Sub Station" ? (
                    <ReportTableSubStationWise
                      setToggle={setToggle}
                      feeder={feeder}
                      zone={zone}
                      circle={circle}
                      division={division}
                      subDivision={subDivision}
                      subStation={subStation}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                    />
                  ) : title === "Feeder" ? (
                    <ReportTableFeederWise
                      setToggle={setToggle}
                      feeder={feeder}
                      zone={zone}
                      circle={circle}
                      division={division}
                      subDivision={subDivision}
                      subStation={subStation}
                      selectedMonth={selectedMonth}
                      selectedYear={selectedYear}
                    />
                  ) : (
                    ""
                  )}
                  <div className="btn-list mt-5">
                    <button className="btn btn-primary me-1">
                      Download Report
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
