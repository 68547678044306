import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sendOtp } from "../../../services/otherServices";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [resetOption, setResetOption] = useState("MOBILE"); // Default reset option
  const [mobileNo, setMobileNo] = useState(""); // Mobile number input state
  const [newPassword, setNewPassword] = useState(123456); // Mobile number input state

  const onSubmit = async (data) => {
    // Add the selected reset option and new password to the form data
    const formData = {
      ...data,
      METHOD: resetOption, // Add the resetOption to the form data
      MOBILE_NO: resetOption === "MOBILE" ? mobileNo : undefined, // Set mobile number only if the option is "MOBILE"
      NEW_PASSWORD: newPassword,
    };

    try {
      let otpDetails = await sendOtp(formData);
      if (otpDetails?.data) {
        navigate("/otp", {
          state: {
            userId: formData.USER_ID,
            mobileNumber: formData.MOBILE_NO, // Pass the mobile number
            email: formData.EMAIL,
            resetOption: formData.METHOD,
            newPassword: formData.NEW_PASSWORD, // Pass the new password
          },
        });
      } else {
        alert(otpDetails?.Message || "Something went wrong, please try again.");
      }
    } catch (error) {
      alert("There was an error processing your request.");
    }
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Forgot Password?</h3>
                <p className="account-subtitle">
                  Select a reset option and provide the necessary information
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* User ID Input */}
                  <div className="input-block">
                    <label>User ID</label>
                    <input
                      className="form-control"
                      type="text"
                      {...register("USER_ID", {
                        required: "User ID is required",
                      })}
                      name="USER_ID"
                    />
                    {errors.USER_ID && (
                      <span className="error-message">
                        {errors.USER_ID.message}
                      </span>
                    )}
                  </div>

                  {/* Reset Option Selection */}
                  <div className="input-block">
                    <label>Reset Option</label>
                    <select
                      className="form-control"
                      value={resetOption}
                      onChange={(e) => setResetOption(e.target.value)}
                      name="RESET_OPTION"
                    >
                      <option value="MOBILE">Mobile</option>
                      <option value="EMAIL">Email</option>
                    </select>
                  </div>

                  {/* Conditional Inputs Based on Reset Option */}
                  {resetOption === "MOBILE" && (
                    <div className="input-block">
                      <label>Mobile Number</label>
                      <input
                        className="form-control"
                        type="number"
                        value={mobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        name="MOBILE_NO"
                      />
                      {errors.MOBILE_NO && (
                        <span className="error-message">
                          {errors.MOBILE_NO.message}
                        </span>
                      )}
                    </div>
                  )}

                  {resetOption === "EMAIL" && (
                    <div className="input-block">
                      <label>Email Address</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("EMAIL", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email address",
                          },
                        })}
                        name="EMAIL"
                      />
                      {errors.EMAIL && (
                        <span className="error-message">
                          {errors.EMAIL.message}
                        </span>
                      )}
                    </div>
                  )}

                  {/* New Password Input */}
                  <div className="input-block">
                    <label>New Password</label>
                    <input
                      className="form-control"
                      type="password"
                      value={newPassword}
                      {...register("NEW_PASSWORD", {
                        required: "New Password is required",
                        minLength: {
                          value: 6,
                          message: "Password must be at least 6 characters",
                        },
                      })}
                      onChange={(e) => setNewPassword(e.target.value)}
                      name="NEW_PASSWORD"
                    />
                    {errors.NEW_PASSWORD && (
                      <span className="error-message">
                        {errors.NEW_PASSWORD.message}
                      </span>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="input-block text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>

                  <div className="account-footer">
                    <p>
                      Remember your password? <Link to="/">Login</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
