import React, { useState, useEffect } from "react";
import { useTable, notification } from "react-table";
import {
  getTableDataFeederWise,
  getTableDataZoneWise,
} from "../../../services/otherServices";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Link } from "react-router-dom";

let rwspan = 0;
function Table({ columns, data, rwspan }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <table
      {...getTableProps()}
      style={{
        border: "1px solid black",
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <thead>
        {headerGroups?.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers?.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  border: "1px solid black",
                  padding: "10px",
                  backgroundColor: "#FEF2CB",
                  textAlign: "center",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.map((row, rowIndex) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells?.map((cell, cellIndex) => {
                // Render Name of Columns with rowSpan of 4

                if (
                  cell.column.Header === "Sr. No." ||
                  cell.column.Header === "Name of Zone" ||
                  cell.column.Header === "Estimated Unbilled Consumption" ||
                  cell.column.Header === "Estimated Unavailable Consumption" ||
                  cell.column.Header === "Actual Consumption" ||
                  cell.column.Header === "No. of FDR (Input Received)" ||
                  cell.column.Header === "No. of FDR (Input Not Received)" ||
                  // cell.column.Header === "Name of Zone" ||
                  cell.column.Header === "Name of Sub Divisions" ||
                  cell.column.Header === "Total Input Consumption" ||
                  cell.column.Header === "Total Billed Consumption" ||
                  cell.column.Header === "T&D Loss" ||
                  cell.column.Header === "T&D Loss(%)" ||
                  cell.column.Header === "Current Assessment" ||
                  cell.column.Header === "Collected Amount" ||
                  cell.column.Header === "Billing Efficiency" ||
                  cell.column.Header === "Billing Efficiency(%)" ||
                  cell.column.Header === "Collection Efficiency" ||
                  cell.column.Header === "Collection Efficiency(%)" ||
                  cell.column.Header === "AT&C Loss(%)"
                ) {
                  return rowIndex % rwspan === 0 ? (
                    <td
                      {...cell.getCellProps()}
                      rowSpan={rwspan} // Span across 4 rows
                      style={{
                        border: "1px solid black",
                        padding: "10px",
                        textAlign: "center", // Optional: center the content
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  ) : null; // Do not render "Name of Zone" column for the next 3 rows
                }
                // Render all other cells as usual
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{ border: "1px solid black", padding: "10px" }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ReportTableZoneWise({
  selectedMonth,
  selectedYear,
  zone,
  feeder,
  setToggle,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [feederDetails, setFeederDetails] = useState([]);
  // const [zones, setZones] = useState([]);
  const tableDetails = async () => {
    try {
      const fetchedTableData = await getTableDataZoneWise(
        localStorage.getItem("report_year"),
        localStorage.getItem("report_month"),
        zone.value
      );
      setTableData(fetchedTableData?.data);
      setFeederDetails(fetchedTableData?.finalObj);

      rwspan = fetchedTableData?.data.length;
    } catch (error) {
      // notification.error({ message: "Error fetching zone data" });
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    tableDetails();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: "Sr. No.",
      Cell: (row) => row.row.index + 1,
      accessor: "ID",
    },
    {
      Header: "Name of Zone",
      accessor: "ZONE_CODE",
    },
    {
      Header: "Consumer Profile",
      columns: [
        {
          Header: "Consumer Category",
          accessor: "PROCESS", // Expect this data in each row
        },
        {
          Header: "Live Consumers",
          accessor: "LIVE_CONSUMER",
        },
        {
          Header: "Other Consumers",
          accessor: "OTHER_CONSUMER",
        },
      ],
    },
    {
      Header: "Feeder Details",
      columns: [
        {
          Header: "No. of FDR (Input Received)",
          accessor: "NO_OF_FDR_INPUT_RECEIVED",
        },
        {
          Header: "No. of FDR (Input Not Received)",
          accessor: "NO_OF_FDR_INPUT_NOT_RECEIVED",
        },
        {
          Header: "Actual Consumption",
          accessor: "ACTUAL_CONSUMPTION_FDR",
        },
        {
          Header: "Estimated Unbilled Consumption",
          accessor: "ESTIMATED_UNBILLED_CONSUMPTION_FDR",
        },
        {
          Header: "Estimated Unavailable Consumption",
          accessor: "ESTIMATED_UNAVAILABLE_CONSUMPTION_FDR",
        },
        {
          Header: "Total Input Consumption",
          accessor: "TOTAL_INPUT_CONSUMPTION_FDR",
        },
      ],
    },
    {
      Header: "Billing Details on a Pro Data Basis",
      columns: [
        {
          Header: "Bill Made",
          accessor: "BILL_MADE",
        },
        {
          Header: "Actual Consumptionn",
          accessor: "ACTUAL_CONSUMPTION_CONSUMER",
        },
        {
          Header: "Provisional Consumption",
          accessor: "PROVISIONAL_CONSUMPTION_CONSUMER",
        },
        {
          Header: "Estimated Unbiled Consumption",
          accessor: "ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER",
        },
        {
          Header: "Estimated Unavailable Consumptionn",
          accessor: "ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER",
        },
        {
          Header: "Category Consumption",
          accessor: "CATEGORY_CONSUMPTION_CONSUMER",
        },
        {
          Header: "Total Billed Consumption",
          accessor: "TOTAL_BILLED_CONSUMPTION",
        },
      ],
    },
    {
      Header: "T&D Loss",
      accessor: "TD_LOSS",
    },
    {
      Header: "T&D Loss(%)",
      accessor: "TD_LOSS_PERCENT",
    },
    {
      Header: "Commercial Parameters",
      columns: [
        {
          Header: "Current Assessment",
          accessor: "CURRENT_ASSESSMENT",
        },
        {
          Header: "Collected Amount",
          accessor: "COLLECTED_AMOUNT",
        },
        {
          Header: "Billing Efficiency",
          accessor: "BILLING_EFFICIENCY",
        },
        {
          Header: "Billing Efficiency(%)",
          accessor: "BILLING_EFFICIENCY_PERCENT",
        },
        {
          Header: "Collection Efficiency",
          accessor: "COLLECT_EFFICIENCY",
        },
        {
          Header: "Collection Efficiency(%)",
          accessor: "COLLECT_EFFICIENCY_PERCENT",
        },
      ],
    },
    {
      Header: "AT&C Loss(%)",
      accessor: "AT_AND_C_LOSS_PERCENT",
    },
  ]);

  let nameofFeederPrev = ""; // Initialize the previous feeder name
  let feederName = "";
  let totalBilledConsumption = 0; // Initialize the total billed consumption
  let totalInputConsumption = 0; // Initialize the total i/p consumption
  let tdLoss = 0;
  let tdLossPerc = 0;
  let billEff = 0;
  let billEffPerc = 0;
  let currAssmnt = 0;
  let collAmt = 0;
  let fdrCnt = feederDetails.NO_OF_FDR_INPUT_RECEIVED;
  let fdrNotCnt = feederDetails.NO_OF_FDR_INPUT_NOT_RECEIVED;
  let actualConsumptionFDR = feederDetails.ACTUAL_CONSUMPTION_FDR;
  let estiUnbillConsumptionFDR =
    feederDetails.ESTIMATED_UNBILLED_CONSUMPTION_FDR;
  let estiUnavailConsumptionFDR =
    feederDetails.ESTIMATED_UNAVAILABLE_CONSUMPTION_FDR;
  let cnt = 1;

  totalInputConsumption =
    actualConsumptionFDR + estiUnbillConsumptionFDR + estiUnavailConsumptionFDR;
  tableData?.map((data) => {
    // console.log("datadata", data);
    totalBilledConsumption +=
      Number(data?.ACTUAL_CONSUMPTION_CONSUMER || 0) +
      Number(data?.PROVISIONAL_CONSUMPTION_CONSUMER || 0) +
      Number(data?.ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER || 0) +
      Number(data?.ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER || 0);

    // calculating NO_OF_FDR_INPUT_RECEIVED & NO_OF_FDR_INPUT_NOT_RECEIVED
    if (feederName !== data?.FEEDER_CODE) {
      //   actualConsumptionFDR += Number(data.ACTUAL_CONSUMPTION_CONSUMER || 0);
      //   estiUnbillConsumptionFDR += Number(
      //     data.ESTIMATED_UNBILLED_CONSUMPTION_FDR || 0
      //   );
      //   estiUnavailConsumptionFDR += Number(
      //     data.ESTIMATED_UNAVAILABLE_CONSUMPTION_FDR || 0
      //   );

      //   if (data.NO_OF_FDR_INPUT_RECEIVED === "1") {
      //     fdrCnt++;
      //   }
      //   if (data.NO_OF_FDR_INPUT_NOT_RECEIVED === "1") {
      //     fdrNotCnt++;
      //   }
      feederName = data?.FEEDER_CODE;
    }

    // other calculations
    if (nameofFeederPrev === "") {
      // If it's the first iteration, set the previous feeder name
      nameofFeederPrev = data?.ZONE_CODE;
    }

    if (nameofFeederPrev === data?.ZONE_CODE) {
      // If the current feeder is the same as the previous one, accumulate the consumption

      // if (cnt > 0) {
      //   totalInputConsumption +=
      //     Number(data?.ACTUAL_CONSUMPTION_FDR || 0) +
      //     Number(data?.ESTIMATED_UNBILLED_CONSUMPTION_FDR || 0) +
      //     Number(data?.ESTIMATED_UNAVAILABLE_CONSUMPTION_FDR || 0);
      // }
      //   totalBilledConsumption +=
      //     Number(data?.ACTUAL_CONSUMPTION_CONSUMER || 0) +
      //     Number(data?.PROVISIONAL_CONSUMPTION_CONSUMER || 0) +
      //     Number(data?.ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER || 0) +
      //     Number(data?.ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER || 0);

      //   totalBilledConsumption += data.CATEGORY_CONSUMPTION_CONSUMER;

      tdLoss = totalInputConsumption - totalBilledConsumption;
      tdLossPerc = (tdLoss / totalInputConsumption) * 100 || 0;
      billEff = totalBilledConsumption / totalInputConsumption || 0;
      billEffPerc = (totalBilledConsumption / totalInputConsumption) * 100 || 0;
      cnt -= 1;
    } else {
      // When a new feeder is encountered, reset totalBilledConsumption and update the previous feeder name
      // if (cnt > 0) {
      //   totalInputConsumption +=
      //     Number(data?.ACTUAL_CONSUMPTION_FDR || 0) +
      //     Number(data?.ESTIMATED_UNBILLED_CONSUMPTION_FDR || 0) +
      //     Number(data?.ESTIMATED_UNAVAILABLE_CONSUMPTION_FDR || 0);
      //   // fdrCnt += Number(data?.FEEDER_CODE || 0);
      //   // {
      //   //   console.log(
      //   //     "fdrCntfdrCnt",
      //   //     fdrCnt,
      //   //     "data.NO_OF_FDR_INPUT_RECEIVED",
      //   //     data.NO_OF_FDR_INPUT_RECEIVED
      //   //   );
      //   // }
      //   // fdrNotCnt += Number(data?.NO_OF_FDR_INPUT_NOT_RECEIVED || 0);
      // }
      //   totalBilledConsumption += data.CATEGORY_CONSUMPTION_CONSUMER;
      // Number(data?.ACTUAL_CONSUMPTION_CONSUMER || 0) +
      // Number(data?.PROVISIONAL_CONSUMPTION_CONSUMER || 0) +
      // Number(data?.ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER || 0) +
      // Number(data?.ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER || 0);

      // console.log("currAssmnt", currAssmnt);
      // currAssmnt += Number(data.CURRENT_ASSESSMENT || 0);

      tdLoss = totalInputConsumption - totalBilledConsumption;
      tdLossPerc = (tdLoss / totalInputConsumption) * 100;
      billEff = totalBilledConsumption / totalInputConsumption || 0;
      billEffPerc = (totalBilledConsumption / totalInputConsumption) * 100 || 0;
      // Update the previous feeder name to the current one
      nameofFeederPrev = data?.ZONE_CODE;
      cnt -= 1;
    }
    totalBilledConsumption += data?.CATEGORY_CONSUMPTION_CONSUMER;
    //
    currAssmnt += Number(data?.CURRENT_ASSESSMENT || 0);
    collAmt += Number(data?.COLLECTED_AMOUNT || 0);
  });

  const data = tableData?.map((data, index) => ({
    // ID: data.ID,
    ZONE_CODE: data.ZONE_CODE,
    PROCESS: data.PROCESS,
    LIVE_CONSUMER: data.LIVE_CONSUMER || 0,
    OTHER_CONSUMER: data.OTHER_CONSUMER || 0,
    NO_OF_FDR_INPUT_RECEIVED: fdrCnt,
    NO_OF_FDR_INPUT_NOT_RECEIVED: fdrNotCnt,
    ACTUAL_CONSUMPTION_FDR: actualConsumptionFDR,
    ESTIMATED_UNBILLED_CONSUMPTION_FDR: estiUnbillConsumptionFDR,
    ESTIMATED_UNAVAILABLE_CONSUMPTION_FDR: estiUnavailConsumptionFDR,
    // TOTAL_INPUT_CONSUMPTION_FDR: row.TOTAL_INPUT_CONSUMPTION_FDR,
    TOTAL_INPUT_CONSUMPTION_FDR: totalInputConsumption,

    BILL_MADE: data.BILL_MADE || 0,
    ACTUAL_CONSUMPTION_CONSUMER: data.ACTUAL_CONSUMPTION_CONSUMER || 0,
    PROVISIONAL_CONSUMPTION_CONSUMER: Number(
      data.PROVISIONAL_CONSUMPTION_CONSUMER || 0
    ),
    ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER:
      data.ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER || 0,
    ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER:
      data.ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER || 0,
    CATEGORY_CONSUMPTION_CONSUMER:
      Number(data.ACTUAL_CONSUMPTION_CONSUMER || 0) +
      Number(data.PROVISIONAL_CONSUMPTION_CONSUMER || 0) +
      Number(data.ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER || 0) +
      Number(data.ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER || 0),
    TOTAL_BILLED_CONSUMPTION: totalBilledConsumption,
    // Number(data.TOTAL_BILLED_CONSUMPTION) +
    // Number(data.ACTUAL_CONSUMPTION_CONSUMER) +
    // Number(data.PROVISIONAL_CONSUMPTION_CONSUMER) +
    // Number(data.ESTIMATED_UNBILLED_CONSUMPTION_CONSUMER) +
    // Number(data.ESTIMATED_UNAVAILABLE_CONSUMPTION_CONSUMER),
    TD_LOSS: tdLoss,
    TD_LOSS_PERCENT: tdLossPerc,
    CURRENT_ASSESSMENT: currAssmnt,
    COLLECTED_AMOUNT: collAmt,
    BILLING_EFFICIENCY: billEff || 0,
    BILLING_EFFICIENCY_PERCENT: billEffPerc || 0,
    COLLECT_EFFICIENCY: collAmt / currAssmnt,
    COLLECT_EFFICIENCY_PERCENT: (collAmt / currAssmnt) * 100,
    // AT_AND_C_LOSS_PERCENT: (1 - billEff * data.COLLECTED_AMOUNT) * 100,
    AT_AND_C_LOSS_PERCENT: (1 - billEff * (collAmt / currAssmnt)) * 100,
  }));

  return (
    <div className="overflow-y-scroll">
      <div className="col">
        <h3 className="page-title">{"Reports"}</h3>
        <ul className="breadcrumb pointer">
          <li
            className="breadcrumb-item "
            style={{ cursor: "pointer" }}
            onClick={() => setToggle(false)}
          >
            {/* <Link to="/feeder-wise-losses-report"> */}
            {"Zone Wise Report"}
            {/* </Link> */}
          </li>
          <li className="breadcrumb-item active">{"Report Table"}</li>
        </ul>
      </div>
      <Table columns={columns} data={data} rwspan={rwspan} />
    </div>
  );
}

export default ReportTableZoneWise;
